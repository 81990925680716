<template>
  <div>
    <!-- header -->
    <MainHeader />

    <!-- main banner -->
    <MainBanner />

    <!-- side info -->
    <div class="py-10 bg-color-orange">
      <div class="container">
        <div class="md:flex md:justify-center xl:max-w-4xl xl:mx-auto">
          <div v-for="(item, i) in info" :key="i" class="pb-10 md:pb-0 md:w-1/3">
            <h2 class="text-5xl font-bold text-gray-900">{{ item.h2 }}</h2>
            <p class="pt-2 font-medium text-gray-50">{{ item.p }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- What We Do -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-gray-800 lg:text-3xl">
          What <span class="text-color-orange">We Do</span>
        </h1>
        <div class="bg-gray-800 h-0.5 w-8 mx-auto"></div>
        <p class="pt-6 text-sm text-gray-600">
          When it comes to printing service, Color Dot Com Sdn Bhd has grown to
          become a market leader in this industry. Our company was set up in
          Seri Kembangan, Selangor, Malaysia with its operation growing
          tremendously each year. We specialize in industrial vehicle wrapping,
          advertisement wrapping, banner printing, light box display system and
          advertisement display system.
        </p>

        <!-- content -->
        <div class="pt-6 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in products"
            :key="i"
            class="pb-2 md:p-2 md:w-1/2 lg:w-1/3"
          >
            <div
              class="text-gray-800 transition duration-300 ease-in-out bg-white hover:text-color-orange hover:shadow-xl rounded-xl"
            >
              <img :src="item.image" :alt="item.alt" class="rounded-t-xl" />
              <h1 class="py-8 text-base font-bold">{{ item.alt }}</h1>
            </div>
          </div>
        </div>

        <div class="pt-4">
          <p
            class="w-64 py-2 mx-auto font-medium text-white bg-gray-800 rounded-lg"
          >
            <a href="http://www.colordotcom.com/index.php?ws=productsbycat"
              >View Here for More Services</a
            >
          </p>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-white">
          Why <span class="text-color-orange">Choose Us</span>
        </h1>
        <div class="bg-white h-0.5 w-8 mx-auto"></div>
        <div
          class="flex flex-wrap pt-5 lg:max-w-3xl lg:mx-auto md:justify-center"
        >
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="w-1/2 p-1 md:w-1/3"
          >
            <div class="p-4 rounded-lg bg-tg-blue">
              <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
              <h1
                class="pt-4 text-sm font-semibold leading-tight text-center text-white lg:justify-center lg:flex lg:items-center h-14"
              >
                {{ item.alt }}
              </h1>
            </div>
          </div>
        </div>
        <!-- cta btn -->
        <div class="pt-6 md:flex md:justify-center">
          <a href="tel:+60192349778" class="block pb-2 md:pr-1">
            <h1
              class="w-48 py-2 mx-auto font-semibold bg-white rounded-lg shadow-xl text-color-orange"
            >
              Call Us Now
            </h1>
          </a>
          <a href="https://wa.me/60192349778">
            <h1
              class="w-48 py-2 mx-auto font-semibold bg-white rounded-lg shadow-xl text-color-orange"
            >
              Free Consultation
            </h1>
          </a>
        </div>
      </div>
    </div>

    <!-- Price Guide -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold leading-tight text-gray-800">
          Our Price Guide for <br /><span class="text-color-orange"
            >Advertising Stickers Wrapping</span
          >
        </h1>
        <div class="bg-gray-800 h-0.5 w-8 mx-auto"></div>

        <!-- content -->
        <div class="pt-6 lg:flex lg:flex-wrap">
          <div
            v-for="(item, i) in price_guide"
            :key="i"
            class="pb-2 lg:w-1/2 lg:px-1"
          >
            <h1
              class="pb-3 text-base font-semibold leading-tight text-left md:text-lg"
            >
              {{ item.title }}
            </h1>
            <div class="bg-color-orange h-0.5 w-10"></div>
            <div class="flex flex-wrap pt-4">
              <div
                v-for="(price_img, i) in item.list"
                :key="i"
                class="w-1/2 p-1 pb-5 md:w-1/3"
              >
                <div>
                  <img :src="price_img.image" alt="" class="pb-3 mx-auto" />
                  <h1
                    class="py-2 mx-auto text-sm font-semibold text-white bg-gray-800 rounded-lg "
                  >
                    Staring At {{ price_img.price }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our Gallery -->
    <div class="py-10 bg-gray-800">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-white">
          Our <span class="text-color-orange">Gallery</span>
        </h1>
        <div class="bg-white h-0.5 w-8 mx-auto"></div>

        <!-- content -->
        <div class="flex flex-wrap pt-6">
          <div
            v-for="(item, i) in gallery"
            :key="i"
            class="w-1/2 p-1 md:w-1/3 lg:w-1/4"
          >
            <img :src="item.image" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- clienteles -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-gray-800">
          Our <span class="text-color-orange">Clienteles</span>
        </h1>
        <div class="bg-gray-800 h-0.5 w-8 mx-auto"></div>

        <!-- client content -->
        <div class="pt-10">
          <carousel
            :autoplay="true"
            :loop="true"
            :dots="true"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 4, nav: false },
              1000: { items: 5, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in client" :key="i" class="">
              <div class="mx-auto">
                <img :src="item.image" :alt="item.alt" class="w-2/3" />
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- enquire now -->
    <!-- <div id="element" class="py-10 bg-color-orange">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-gray-800">
          Enquire <span class="text-white">Us Now</span>
        </h1>
        <div class="bg-gray-800 h-0.5 w-8 mx-auto"></div>
      </div>
    </div> -->

    <!-- About Us -->
    <div class="py-10 bg-gray-800">
      <div class="container">
        <div class="lg:flex lg:items-start">
        <div class="lg:w-1/2 lg:pr-4">
          <h1 class="pb-3 text-2xl font-semibold text-white">
            About <span class="text-color-orange">Us</span>
          </h1>
          <div class="bg-white h-0.5 w-8 mx-auto"></div>
          <div class="pt-6">
            <p class="text-sm text-white">
              <span class="font-semibold text-color-orange"
                >Color Dot Com S/B</span
              >
              is incorporated to meet customer’s expectations and requirements
              to achieve brilliant results. We have our, passion, high energy
              and experience marketing and production teams to work with you to
              provide the
              <span class="italic font-semibold text-color-orange">
                highest quality, innovative and market-relevant product and
                services</span
              >
              profitably in a competitive advertising and design environment.
              <br /><br />
              As a service organization, we are dedicated to delivering quality
              services to our customer. We believe that only achieve customers
              satisfaction, both party can create mutual trust and grow together
              to face the coming challenges.
            </p>
          </div>
        </div>
        <div id="enquire" class="pt-6 lg:w-1/2 lg:pt-0">
          <div id="element" class="px-3 py-5 bg-color-orange">
            <h1 class="pb-3 text-2xl font-semibold text-gray-800">
              Enquire <span class="text-white">Us Now</span>
            </h1>
            <div class="bg-white h-0.5 w-8 mx-auto"></div>
            <div class="pt-6">
              <!-- feedback.activamedia.com.sg script begins here --><iframe
                allowTransparency="true"
                style="min-height: 530px; height: inherit; overflow: auto"
                width="100%"
                id="contactform123"
                name="contactform123"
                marginwidth="0"
                marginheight="0"
                frameborder="0"
                src="https://feedback.activamedia.com.sg/my-contact-form-6017905.html"
              ></iframe
              ><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer class="py-10">
      <div class="container">
        <h1 class="pb-3 text-2xl font-semibold text-gray-800">
          Locate <span class="text-color-orange">Us</span>
        </h1>
        <div class="bg-gray-800 h-0.5 w-8 mx-auto"></div>
        <div class="pt-3 lg:pt-8 lg:flex">
          <div class="lg:w-1/2 lg:pl-4">
            <img
              src="/images/main-logo.png"
              alt=""
              class="w-64 mx-auto lg:mx-0 lg:w-80 md:w-72"
            />
            <h1
              class="pt-6 text-base font-semibold leading-tight text-gray-800 lg:text-xl lg:text-left"
            >
              21, Jalan PDR 6, Kawasan Perniagaan Desa Ria, 43300 Seri
              Kembangan, Selangor, Malaysia.
            </h1>
            <p
              class="pt-3 text-base font-bold text-gray-800 uppercase lg:text-left"
            >
              <i class="fas fa-phone-alt"></i>
              Office :
              <a href="tel:+60192349778" class="hover:text-color-orange"
                >019-2349778 (CALVIN)</a
              >
              |
              <a href="tel:+60123992825" class="hover:text-color-orange"
                >012-3992825 (CLARENCE)</a
              >
            </p>
          </div>
          <div class="pt-6 lg:w-1/2 lg:pt-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.264604516086!2d101.74691201512361!3d3.0234534547547227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcad77c43f193%3A0xf36814f121b3f04f!2sColor%20Dot%20Com%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1634713708017!5m2!1sen!2smy"
              width="100%"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>
    <!-- Copyright -->
    <div class="py-3 bg-gray-800">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    carousel,
    MainHeader,
    MainBanner,
  },
  data() {
    return {
      products: [
        {
          image: "/images/product-1.jpg",
          alt: "Advertising Stickers Wrapping",
        },
        {
          image: "/images/product-7.jpg",
          alt: "Windows Stickers",
        },
        { image: "/images/product-2.jpg", alt: "Banner Printing" },
        { image: "/images/product-3.jpg", alt: "Light Box Display" },
        {
          image: "/images/product-6.jpg",
          alt: "Backdrop Display",
        },
        {
          image: "/images/product-5.jpg",
          alt: "Escalator Sticker",
        },
        // {
        //   image: "/images/product-4.jpg",
        //   alt: "Advertisement Display Network",
        // },
      ],
      choose_us: [
        {
          image: "/images/icon-1.png",
          alt: "Cost Effective Advertising Impression",
        },
        { image: "/images/icon-2.png", alt: "Affordable Price" },
        { image: "/images/icon-3.png", alt: "Premium Quality Material" },
        { image: "/images/icon-4.png", alt: "Reliable Services" },
        { image: "/images/icon-5.png", alt: "Efficient Services" },
        { image: "/images/icon-6.png", alt: "Guarantee Delivery" },
      ],
      price_guide: [
        {
          title: "Window Wraps/Graphic Lettering (One Way Vision)",
          list: [
            { image: "/images/wrap-1.jpg", price: "RM 180" },
            { image: "/images/wrap-2.jpg", price: "RM 250" },
            { image: "/images/wrap-3.jpg", price: "RM 250" },
          ],
        },
        {
          title: "Graphic & Lettering",
          list: [
            { image: "/images/graphic-1.jpg", price: "RM 180" },
            { image: "/images/graphic-2.jpg", price: "RM 290" },
            { image: "/images/graphic-3.jpg", price: "RM 290" },
          ],
        },
        {
          title: "Spot Graphic Wrap",
          list: [
            { image: "/images/spot-1.jpg", price: "RM 390" },
            { image: "/images/spot-2.jpg", price: "RM 490" },
            { image: "/images/spot-3.jpg", price: "RM 1100" },
          ],
        },
        {
          title: "Partial Wrap",
          list: [
            { image: "/images/partial-1.jpg", price: "RM 880" },
            { image: "/images/partial-2.jpg", price: "RM 1900" },
            { image: "/images/partial-3.jpg", price: "RM 1600" },
          ],
        },
        {
          title: "Full Wrap",
          list: [
            { image: "/images/full-1.jpg", price: "RM 2300" },
            { image: "/images/full-2.jpg", price: "RM 2600" },
            { image: "/images/full-3.jpg", price: "RM 2900" },
          ],
        },
        {
          title: "Graphic Lettering",
          list: [
            { image: "/images/lorry-1.jpg", price: "RM 300" },
            { image: "/images/lorry-2.jpg", price: "RM 450" },
            { image: "/images/lorry-3.jpg", price: "RM 600" },
          ],
        },
        {
          title: "Full Wrap (box)",
          list: [
            { image: "/images/lorry-4.jpg", price: "RM 900" },
            { image: "/images/lorry-5.jpg", price: "RM 1800" },
            { image: "/images/lorry-6.jpg", price: "RM 2100" },
          ],
        },
        {
          title: "Full Wrap (box)",
          list: [
            { image: "/images/lorry-7.jpg", price: "RM 1800" },
            { image: "/images/lorry-8.jpg", price: "RM 2300" },
            { image: "/images/lorry-9.jpg", price: "RM 4500" },
          ],
        },
      ],
      gallery: [
        { image: "/images/gallery-1.jpg" },
        { image: "/images/gallery-2.jpg" },
        { image: "/images/gallery-3.jpg" },
        { image: "/images/gallery-4.jpg" },
        { image: "/images/gallery-5.jpg" },
        { image: "/images/gallery-6.jpg" },
        { image: "/images/gallery-7.jpg" },
        { image: "/images/gallery-8.jpg" },
        { image: "/images/gallery-9.jpg" },
        { image: "/images/gallery-10.jpg" },
        { image: "/images/gallery-11.jpg" },
        { image: "/images/gallery-12.jpg" },
      ],
      client: [
        { image: "/images/client-1.jpg", alt: "" },
        { image: "/images/client-2.jpg", alt: "" },
        { image: "/images/client-3.jpg", alt: "" },
        { image: "/images/client-4.jpg", alt: "" },
        { image: "/images/client-5.jpg", alt: "" },
        { image: "/images/client-6.jpg", alt: "" },
        { image: "/images/client-7.jpg", alt: "" },
      ],
      info: [
        { h2: "99%", p: "Customer Satisfaction" },
        { h2: "16 Years", p: "of Experiences" },
        { h2: "500+", p: "Projects Completed" },
      ],
    };
  },
};
</script>
<style>
.txtShadow {
  text-shadow: 2px 2px 2px #515151;
}
@media (min-width: 768px) {
  #enquire iframe {
    min-height: 520px !important;
  }
}
</style>
