<template>
  <header class="py-2 bg-gray-800 md:py-4">
    <div class="container">
      <div class="md:flex md:justify-between md:items-center">
        <img
          src="/images/main-logo.png"
          alt="Color Dot Sdn Bhd"
          class="w-64 mx-auto md:w-80 md:mx-0"
        />
        <div class="pt-2 lg:justify-end lg:flex lg:flex-wrap">
          <h1 class="w-full pb-1 font-medium text-white md:text-right">OFFICE :</h1>
          <a href="tel:+60192349778" class="block pb-2 lg:pr-1">
            <h1
              class="w-56 py-2 mx-auto text-sm font-semibold text-white transition duration-300 ease-linear rounded-lg lg:mx-0 lg:w-52 hover:bg-color-liteorange bg-color-orange"
            >
              019-2349778 (CALVIN)
            </h1>
          </a>
          <a href="tel:+60123992825">
            <h1
              class="w-56 py-2 mx-auto text-sm font-semibold text-white transition duration-300 ease-linear rounded-lg lg:mx-0 lg:w-52 hover:bg-color-liteorange bg-color-orange"
            >
              012-3992825 (CLARENCE)
            </h1>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style>
</style>